import React from 'react'
import { graphql } from 'gatsby'

import Layout from '../../Layout'
import SEO from '../../components/SEO'

import AsideImage from '../../components/AsideImage/AsideImage'
import Button from '../../components/Button/Button'
import OfferList from '../../components/OfferList'

import { uslugiItems } from './../../constants/uslugiItems'

const UslugiPage = ({ data }) => (
  <Layout
    header="Usługi"
    asideContent={[
      <AsideImage
        key="asideImage"
        srcImage={data.coverPhoto.childImageSharp.fluid}
      >
        <Button to="https://iqdental.app/rejestracja-wizyty/6b3cd188-16dc-42ad-91ae-4a073eb8795a/new">
          Umów wizytę
        </Button>
      </AsideImage>,
    ]}
  >
    <SEO title="Usługi | Gabinet Stomatologiczny Glossy Dental | Stomatolog Dentysta Nadarzyn" />
    <OfferList items={uslugiItems} />
  </Layout>
)

export default UslugiPage

export const pageQuery = graphql`
  query {
    coverPhoto: file(relativePath: { eq: "image3.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1000) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
