import React from 'react'
import styled from 'styled-components'
import SVG from 'react-inlinesvg'

import Button from '../Button/Button'

const StyledWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 50px;
`

const StyledItem = styled.div`
  position: relative;
  display: block;
  text-align: center;
  padding: 30px 30px 0;

  h3 {
    margin: 0 0 10px;
    font-size: 20px;
  }

  p {
    margin: 0 0 10px;
    padding: 0 10%;
    font-size: 16px;
    line-height: 1.33;
  }
`

const StyledIcon = styled(SVG)`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 60px;
  margin: 0 0 20px;
  svg {
    width: 100%;
    height: 100%;
  }
`

const OfferList = ({ items }) => {
  return (
    <StyledWrapper>
      {items.map(
        item =>
          !item.image && (
            <StyledItem key={item.id}>
              <StyledIcon src={item.icon} />
              <h3>{item.header}</h3>
              <p>{item.desc}</p>
              <Button to={`/uslugi/${item.slug}`} small>
                Zobacz więcej
              </Button>
            </StyledItem>
          )
      )}
    </StyledWrapper>
  )
}

export default OfferList
